.swagger-editor {
  &__validation-table {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 300;
    border: none;

    thead {
      flex: 0 0 auto;
      flex-direction: row;
      color: #444;
      background: rgb(238, 238, 238);
      border-bottom: 1px solid lightgray;
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      outline: none;
    }

    thead th {
      min-width: 2.0rem;
      padding-left: 0.25rem;
    }

    tbody {
      flex: 1 1 auto;
      flex-direction: row;
      max-height: 100px;
      overflow: auto;
    }

    tbody td {
      min-width: 2.0rem;
      padding-left: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
